:root {

    --base-color: 174;
    /* Changing base hue to match the hue of #009688 */

    --black: hsla(var(--base-color), 0%, 0%, 1);
    --white: hsla(var(--base-color), 0%, 100%, 1);
    --theme-black: hsla(var(--base-color), 2%, 30%, 1);
    --theme-white: hsla(var(--base-color), 100%, 99%, 1);
    --light-color: hsla(var(--base-color), 4%, 67%, 1);
    --light-grey-bg: hsla(var(--base-color), 0%, 96%, 1);

    --theme-color: hsla(var(--base-color), 100%, 29%, 1);
    /* Adjusted to match the lightness of #009688 */
    --theme-color-dark: hsla(var(--base-color), 100%, 42%, 1);
    /* You might adjust this for a darker version of the theme color */
    --light-theme-bg: hsla(var(--base-color), 100%, 99%, 1);
    --light-theme-bg1: hsla(var(--base-color), 65%, 92%, 1);



    --light-font: BalooTamma2-SemiBold;
    --regular-font: BalooTamma2-Regular;
    --medium-font: BalooTamma2-Medium;
}

@font-face {
    font-family: BalooTamma2-SemiBold;
    src: url('../fonts/BalooTamma2-SemiBold.ttf');
}

@font-face {
    font-family: BalooTamma2-Regular;
    src: url('../fonts/BalooTamma2-Regular.ttf');
}

@font-face {
    font-family: BalooTamma2-Medium;
    src: url('../fonts/BalooTamma2-Medium.ttf');
}

html {
    position: relative;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: var(--light-font);
    width: 100%;
    overflow-x: hidden;
    padding: 0px;
    margin: 0px;
    background-color: var(--white);
    color: var(--theme-black);
    zoom: 0.75;
}

.modal-backdrop {
    zoom: 1.5;
}

.nopadding {
    padding: 0px !important;
}

a,
a:hover {
    color: inherit;
    text-decoration: none;
}

p {
    font-size: 16px;
    line-height: 26px;
    color: var(--light-color);
}

.ft-14 {
    font-size: 15px !important;
}

.ft-18 {
    font-size: 20px !important;
}

.theme-bg {
    background-color: var(--theme-color) !important;
}

.theme-color {
    color: var(--theme-color) !important;
}

.dark-color,
.dark-color p {
    color: var(--theme-black) !important;
}

.light-color {
    color: var(--light-color) !important;
}

.white-color,
.white-color p {
    color: var(--theme-white) !important;
}

.regular {
    font-family: var(--regular-font) !important;
}

.medium {
    font-family: var(--medium-font) !important;
}

.ft-18-title {
    font-size: 20px;
    color: var(--theme-black);
    font-family: var(--regular-font);
}

.light-theme-bg {
    background-color: var(--light-theme-bg1) !important;
}

/*login register page*/

.login-box-height {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-box {
    padding: 20px;
    display: block;
    max-width: 520px;
    margin: auto;
    height: auto;
    max-height: 100vh;
    overflow: auto;
}

.login-btn-sec {
    padding: 0px 50px;
}

.login-box::-webkit-scrollbar {
    display: none;
}

.theme-btn {
    display: inline-block;
    color: var(--white) !important;
    padding: 9px 25px;
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    box-shadow: 0px 3px 6px #00000008 !important;
    border-radius: 12px;
    outline: 0 !important;
    transition: all ease .5s;
}

.theme-btn:hover {
    background-color: var(--theme-color-dark);
}

.btn-icon {
    margin-right: 10px;
    margin-top: -3px;
}

.white-border-btn {
    color: var(--theme-black) !important;
    background-color: var(--white);
    border-color: #F2F2F2;
}

.white-border-btn:hover {
    background-color: var(--light-grey-bg);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    color: #6B6C6D;
    font-family: var(--regular-font);
    margin-bottom: 8px;
}

.form-control {
    color: #6B6C6D;
    background-color: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    padding: 9px 20px;
    color: var(--theme-black);
}

.form-select {
    background-color: #FFFFFF !important;
}

.form-control::placeholder {
    color: #BABDC1;
}

.form-control:focus {
    box-shadow: none;
    outline: 0;
    border-color: var(--theme-color);
}

.form-control:disabled,
.form-control:read-only {
    background-color: var(--light-theme-bg1);
    opacity: 1;
}

.hover-underline:hover {
    text-decoration: underline;
}

.custom.checkbox>label,
.custom.radio>label {
    position: relative;
    cursor: pointer;
    padding-left: 32px;
}

.custom input[type="checkbox"],
.custom input[type="radio"] {
    position: relative;
    margin-left: -26px;
    margin-right: 12px;
    cursor: pointer;
}

.custom input[type="checkbox"]:after,
.custom input[type="radio"]:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -4px;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
}

.custom input[type="checkbox"]:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 1px;
    top: 4px;
    z-index: 1;
    width: 9px;
    height: 5px;
    border: 1px solid var(--theme-color);
    border-top-style: none;
    border-right-style: none;
}

.custom input[type="checkbox"]:checked:after,
.custom input[type="radio"]:checked:after {
    border-color: var(--theme-color);
}

.custom input[type="checkbox"]:checked:before {
    transform: rotate(-45deg) scale(1, 1);
}

.custom input[type="checkbox"]:after {
    border-radius: 3px;
}

.custom input[type="radio"] {
    top: -2px;
}

.custom input[type="radio"]:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 3px;
    left: 0px;
    z-index: 1;
    width: 10px;
    height: 10px;
    background: var(--theme-color);
    border-radius: 50%;
}

.custom input[type="radio"]:checked:before {
    transform: scale(1, 1);
}

.custom input[type="radio"]:after {
    border-radius: 50%;
}

.input-icon-div {
    position: relative;
}

.input-icon-div .form-control {
    padding-left: 40px;
}

.input-icon-div .input-icon {
    position: absolute;
    left: 12px;
    bottom: 11px;
    color: var(--light-color);
}

.login-box form {
    width: 100%;
}

.sign-form {
    display: none;
}

.hero-bg-img {
    width: 100%;
    height: 133.5vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 10px;
    display: flex;
}

.carousel-indicators {
    margin-bottom: 2rem;
}

.carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    border: none;
}

.hero-content {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding-bottom: 3rem;
}

.hero-img {
    max-height: 65vh;
    width: auto;
}

.mini-title {
    font-size: 25px;
    line-height: 35px;
    font-family: var(--medium-font);
}

.login-btn {
    background-color: #007AFF !important;
    border-color: #007AFF !important;
}

.login-btn:hover {
    background-color: #0068d6 !important;
}

.login-blue-color {
    color: #007AFF !important;
}

.login-box .custom input[type="checkbox"]:before {
    border-color: #007AFF;
}

.login-box .custom input[type="checkbox"]:checked:after,
.login-box .custom input[type="radio"]:checked:after {
    border-color: #007AFF;
}

.login-box .custom input[type="radio"]:before {
    background: #007AFF;
}

.login-theme-color {
    color: var(--theme-color) !important;
}

/*admin theme*/

/*sidebar-div*/
.sidebar-div-box {
    width: 280px;
    height: 150vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background: #FAFAFA;
    box-shadow: 0px 0px 20px #0000000D;
    overflow: hidden;
    z-index: 999;
}

.sidebar-header {
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
}

.sidebar-header a {
    cursor: pointer;
}

.sidebar-div {
    padding: 20px 25px;
    height: calc(135vh - 110px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-div::-webkit-scrollbar {
    width: 8px;
}

.sidebar-div::-webkit-scrollbar-track {
    background: #f3f4f6;
}

.sidebar-div::-webkit-scrollbar-thumb {
    background: #d1d5db;
}

.sidebar-list {
    background-color: transparent;
    border-radius: 15px;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: var(--light-color);
    font-family: var(--regular-font);
    transition: all ease .5s;
    cursor: pointer;
}

.sidebar-list.active {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 20px #00000003;
    color: var(--theme-black);
}

.sidebar-list::before {
    content: '';
    opacity: 0;
    visibility: hidden;
    width: 7px;
    height: 36px;
    display: inline-block;
    background: var(--theme-color);
    box-shadow: 0px 3px 20px #00000005;
    border-radius: 0px 9px 9px 0px;
    transition: all ease .5s;
    position: absolute;
    left: 0px;
}

.sidebar-list.active::before {
    visibility: visible;
    opacity: 1;
}

.sidebar-list:hover {
    color: var(--theme-black);
}

.sidebar-list-icon {
    display: inline-block;
    min-width: 1.25rem;
    width: 1.25rem;
    height: auto;
    margin-right: .75rem;
    margin-top: -5px;
}

.sidebar-list-icon svg {
    width: 16px;
    height: auto;
}

.upgrade-sidebar-box {
    background-color: #FFFFFF;
    box-shadow: 20px 20px 20px #00000008;
    border-radius: 15px;
    padding: 8px 15px 15px;
    margin-bottom: 50px;
}

.upgrade-profile {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.disable-layer {
    position: relative;
    overflow: hidden;
}

.disable-layer::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: .75;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 100;
}

.sidebar-menu-close {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--theme-black);
}


/*main-div*/
.main-div {
    width: 100%;
    min-height: 100vh;
    padding-left: 280px;
    background-color: #F8F8F8;
    height: 135vh;
}



/*header div*/
.header-div {
    padding: 0px 50px;
    width: 100%;
    background-color: #F8F8F8;
    position: sticky;
    top: 0px;
    z-index: 99;
}

.header-div-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #E3E3E3;
}

.search-input .form-control {
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0px 3px 20px #00000003;
    border-radius: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 50px;
}

.search-input.input-icon-div .input-icon {
    left: 18px;
    bottom: 13px;
}

.header-btn {
    color: var(--theme-black) !important;
    background-color: var(--white);
    border: none;
    min-width: 160px;
    text-align: left;
    padding: 6px 35px 6px 10px;
    white-space: nowrap;
    box-shadow: none !important;
    border-radius: 30px;
    outline: 0 !important;
    transition: all ease .5s;
    display: inline-flex;
    align-items: center;
    margin-right: 12px;
    font-weight: bold;
    font-family: var(--regular-font);
}

.rounded-btn {
    border-radius: 30px;
}

.round-color-icon {
    height: 35px;
    min-width: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 8px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-grey-bg);
}

.header-minute-text {
    display: flex;
    align-items: center;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #E3E3E3;
    white-space: nowrap;
    font-family: var(--medium-font);
}

.dropdown-toggle {
    padding: 0px;
    box-shadow: none !important;
    outline: 0 !important;
}

.no-toggle-icon::after {
    display: none;
}

.dropdown-menu {
    border: none;
    border-radius: 6px;
    padding: .25rem 0rem;
    overflow: hidden;
    box-shadow: 0px 0px 10px #00000029;
}

.dropdown-item {
    color: var(--theme-black);
    padding: .3rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
    color: var(--theme-black);
    background-color: var(--light-grey-bg);
}

.selected-speaker {
    color: var(--theme-black);
    background-color: var(--light-grey-bg);
}

@media all {
    .header-div .dropdown-menu {
        top: 180% !important;
        right: 0px !important;
        left: auto !important;
        transform: none !important;
        transition: all ease .3s;
        display: block;
        opacity: 0;
        visibility: hidden;
    }

    .header-div .dropdown:hover .dropdown-menu {
        display: block;
        opacity: 1;
        visibility: visible;
        top: 100% !important;
    }
}

.brand-drop-logo {
    height: 25px;
    width: auto;
}

.res-header-dash-sticky {
    padding: 5px 20px;
    background-color: #F8F8F8;
    border-bottom: 1px solid #E3E3E3;
    position: sticky;
    top: 63.83px;
    z-index: 98;
}

.search-modal .btn-close {
    position: relative !important;
    top: auto;
    right: auto;
}

.search-modal .modal-dialog {
    width: 100%;
    margin: 0px;
    max-width: 100%;
}

.search-modal .modal-content {
    border-radius: 0px;
    border: none;
}

.search-modal .modal-body {
    padding: 10px;
}

.search-modal .modal-body .search-input {
    width: 100%;
}


/*content-area*/
.content-area {
    position: relative;
    padding: 30px 50px;
}

.card-title {
    font-size: 20px;
    color: var(--theme-black);
    font-family: var(--medium-font);
    margin-bottom: 25px;
}

.card-margin {
    margin-bottom: 30px;
}

.card {
    display: block;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 20px 20px 20px #1A242E08;
    border-radius: 36px;
    overflow: hidden;
}

.card-hover {
    cursor: pointer;
    transition: all ease .5s;
}

.card-hover:hover {
    background-color: var(--light-theme-bg1);
    /*    box-shadow: 20px 20px 20px #1a242e14;*/
}

.card-100 {
    height: 100%;
}

.card-padding {
    padding: 20px;
}

.img-icon-list {
    height: 85px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-icon-list span {
    margin: 10px 15px;
}

.img-icon-list img {
    max-height: 65px;
    width: auto;
}

.team-profiles-div {
    display: inline-flex;
}

.team-profiles-img {
    height: 60px;
    min-width: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid var(--theme-color);
    margin-left: -30px;
    object-fit: cover;
    background-color: var(--theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-profiles-div img:first-child {
    margin-left: 0px;
}

.pointer {
    cursor: pointer;
}

.table {
    border-color: #E3E3E3 !important;
    color: var(--light-color);
    vertical-align: middle;
}

.table th {
    font-family: var(--regular-font);
    font-weight: initial;
    color: var(--theme-black);
}

.table th,
.table td {
    padding: 5px 10px;
}

.table tbody tr td:first-child,
.table tbody tr th:first-child {
    width: 40px;
}

.table tbody tr:last-child td,
.table tbody tr:last-child th {
    border-bottom: none;
}

.action-icon-list {
    display: inline-flex;
}

.icons-hover {
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s ease;
}

.icons-hover:hover,
.icons-hover:focus {
    border-radius: 50%;
    background-color: var(--light-grey-bg);
}

.tooltip-inner {
    background-color: var(--light-grey-bg);
    color: var(--theme-black);
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom]>.tooltip-arrow::before {
    border-bottom-color: var(--light-grey-bg);
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top]>.tooltip-arrow::before {
    border-top-color: var(--light-grey-bg);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left]>.tooltip-arrow::before {
    border-left-color: var(--light-grey-bg);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right]>.tooltip-arrow::before {
    border-right-color: var(--light-grey-bg);
}

@media (min-width: 767px) {
    .modal-dialog {
        max-width: 650px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.no-file-card {
    background-color: var(--light-theme-bg);
    width: 50%;
}

.modal-content {
    background: var(--light-theme-bg);
    border-radius: 28px;
    overflow: hidden;
    border: none;
    z-index: 99;
}

.modal-content-right-radius {
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
}

.modal-body,
.modal-body-padding {
    padding: 35px 50px;
}

.btn-close {
    opacity: .5;
    position: absolute;
    right: 20px;
    top: 20px;
    box-shadow: none !important;
    outline: 0 !important;
    z-index: 99;
}

.round-input {
    background-color: #FFFFFF;
    border: none;
    box-shadow: 0px 3px 20px #00000003 !important;
    border-radius: 30px;
}

.border-input {
    border: 1px solid #E6E6E6;
    box-shadow: none !important;
}

.img-list img {
    margin-right: 15px;
    margin-bottom: 15px;
}

.medium-title {
    font-size: 30px;
    line-height: 40px;
    font-family: var(--medium-font);
}

.filename-container {
    display: flex;
    /* Uses flexbox layout */
    align-items: center;
    /* Vertically centers children if they're of different heights */
}

.filename {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 350px;
    /* Adjust this if necessary */
}

.small-title {
    font-size: 22px;
    line-height: 33px;
    color: var(--theme-black);
    font-family: var(--regular-font);
    display: flex;
    align-items: center;
    /* Vertically center content */
}

.small-title span,
.small-title a {
    white-space: nowrap;
    /* Prevents the content from wrapping to the next line */
}

.small-title .filename {
    flex: 1;
    /* This will make the filename take the maximum available space */
    overflow: hidden;
    /* Hide excess content */
    text-overflow: ellipsis;
    /* Add ... for overflow content */
    margin: 0 10px;
    /* Add some space around filename */
    width: initial;
    max-width: 250px;
}

.accordion-item {
    border: 1px solid #E3E3E3;
    position: relative;
    padding-left: 25px;
}

.accordion-button {
    font-family: var(--medium-font);
    color: var(--theme-black) !important;
    padding: 25px 0px;
    line-height: 24px;
    background-color: #fff !important;
    box-shadow: none !important;
    border: none;
    align-items: flex-start;
}

.accordion-flush .accordion-collapse .accordion-body {
    color: var(--theme-black);
    padding: 0px;
    padding-bottom: 15px;
}

.collapse-icon {
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 23px;
    color: var(--theme-color);
    z-index: 9;
    font-size: 20px;
    line-height: 24px;
}

.accordion-button::after {
    content: "\f107";
    font: normal normal normal 24px/1 FontAwesome;
    background-image: none !important;
    color: var(--theme-color);
    height: auto;
    width: auto;
}

.card-padding-small {
    padding: 15px;
}

.border-dashed-box {
    border: 1px dashed #D9D9D9;
    border-radius: 22px;
    padding: 15px;
    height: 100%;
}

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sec-pad-top {
    padding-top: 40px;
}

.record-time-div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    font-size: 24px;
    font-family: var(--medium-font);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border: 3px solid #F9FCFF;
}

.transparent-white-btn {
    color: var(--white) !important;
    background-color: var(--theme-color);
    border: 2px solid var(--white);
    box-shadow: 0px 3px 20px #00000003;
    border-radius: 15px;
}

.transparent-white-btn:hover {
    background-color: var(--white);
    color: var(--theme-color) !important;
}

.progress {
    width: 100%;
    height: 18px;
    border-radius: 50px;
    background-color: var(--light-theme-bg1);
}

.progress-bar {
    background-color: var(--theme-color);
}

.nav-pills {
    border-bottom: 1px solid #E3E3E3;
}

.icon-tab.nav-pills .nav-link {
    height: 55px;
    min-width: 100px;
}

.nav-pills .nav-link {
    background: transparent;
    border: none;
    border-radius: 0px;
    border-bottom: 3px solid transparent;
    padding: 0px 15px;
    color: var(--theme-black);
    opacity: .5;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--theme-black);
    background-color: transparent;
    border-bottom-color: var(--theme-color);
    opacity: 1;
}

.dark-p p {
    color: var(--theme-black);
    font-family: var(--regular-font);
}

ol,
ul {
    margin-bottom: 0px;
    padding-left: 1rem;
}

.drag-upload-div {
    background-color: #FFFFFF;
    border: 1px dashed var(--theme-color);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    min-height: 60px;
    text-align: center;
    cursor: pointer;
    padding: 10px
}

.underline {
    text-decoration: underline !important;
}

.img-shadow-box {
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, .08);
}

.logout-icon-div {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    display: inline-flex;
    align-content: center;
    justify-content: center;
}

.logout-icon {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #FF3938;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    transition: all ease .5s;
}

.logout-box-card:hover .logout-icon {
    height: 70px;
    width: 70px;
}

.account-profile {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

hr {
    background-color: #E3E3E3;
    opacity: 1;
}

.profile-list {
    display: flex;
    margin-bottom: 15px;
}

.profile-list p {
    margin-bottom: 0px;
}

.profile-list-title {
    min-width: 100px;
    padding-right: 10px;
}

.profile-list-desc-div {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 100px);
}

.profile-list-desc {
    color: var(--theme-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desc-copy-icon {
    min-width: 16px;
}

.profile-card-box {
    position: relative;
    cursor: pointer;
}

.profile-card-hover-div {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #007AFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all ease .5s;
}

.profile-card-box:hover .profile-card-hover-div {
    opacity: 1;
}

.green-bg {
    background-color: #00D13F !important;
}

.purple-bg {
    background-color: #6A6CFF !important;
}

.yellow-bg {
    background-color: #DC9301 !important;
}

.red-bg {
    background-color: #FF3938 !important;
    border-color: #FF3938 !important;
}

.text-danger {
    color: #FF3938 !important;
}

.edit-profile-div {
    display: inline-block;
    position: relative;
}

.edit-profile-icon {
    position: absolute;
    bottom: 4px;
    right: -2px;
    cursor: pointer;
    height: 35px;
    min-width: 35px;
    width: 35px;
    border-radius: 50%;
    padding: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-white);
    background-color: var(--theme-color);
}

.round-theme-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: var(--light-theme-bg1);
    border: 1px solid var(--theme-color);
}

.red-border-btn {
    color: #FF3938 !important;
    background-color: var(--white);
    border-color: #FF3938;
}

.red-border-btn:hover {
    color: var(--white) !important;
    background-color: #FF3938;
}

.min-btn-width {
    min-width: 140px;
}

.table-page-icon {
    min-width: 35px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: var(--light-theme-bg1);
    color: var(--theme-color);
}

.table-serach {
    width: 100%;
    max-width: 320px;
}

.pagination-sm .page-link {
    padding: 0px;
    font-size: .875rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px !important;
    background-color: #F3F3F3 !important;
    border: none !important;
    margin-left: 5px !important;
    font-family: var(--regular-font);
    color: var(--light-color) !important;
    box-shadow: none !important;
    outline: 0 !important;
}

.page-item.active .page-link {
    background-color: var(--theme-color) !important;
    color: var(--theme-white) !important;
}

.small-table-btn {
    display: inline-block;
    color: var(--white) !important;
    padding: 8px 20px;
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    box-shadow: 0px 3px 6px #00000003 !important;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    outline: 0 !important;
}

.green-btn-bg {
    background-color: #00D13F !important;
    border-color: #00D13F !important;
}

.purple-btn-bg {
    background-color: #6A6CFF !important;
    border-color: #6A6CFF !important;
}

.red-btn-bg {
    background-color: #FF3938 !important;
    border-color: #FF3938 !important;
}

.blue-logo-text {
    color: #007AFF;
}

.pink-logo-text {
    color: #A60298;
}

.yellow-logo-text {
    color: #FF9819;
}

.green-logo-text {
    color: #049426;
}

.amigotor-color {
    color: #009688;
}

.red-logo-text{
    color: #ff5757;
}

.text-switch .btn-toggle {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 14px;
    width: 51px;
    border-radius: 50px;
    color: #000;
    background: #EFEFEF;
    border: none;
    font-weight: 600;
}

.text-switch .btn-toggle:focus,
.text-switch .btn-toggle.focus,
.text-switch .btn-toggle:focus.active,
.text-switch .btn-toggle.focus.active {
    outline: none;
    box-shadow: none;
}

.text-switch .btn-toggle:before,
.text-switch .btn-toggle:after {
    width: 5rem;
    font-family: var(--regular-font);
    color: var(--theme-black);
    text-transform: initial;
    letter-spacing: 1px;
    position: absolute;
    bottom: -5px;
    transition: opacity 0.5s;
}

.text-switch .btn-toggle:before {
    content: var(--before-content, 'Monthly');
    text-align: right;
    padding-right: 10px;
    left: -5rem;
    width: fit-content;
}

.text-switch .btn-toggle:after {
    content: var(--after-content, 'Annual');
    text-align: left;
    padding-left: 10px;
    right: -5rem;
    opacity: 0.5;
}

.text-switch .btn-toggle.active:before {
    opacity: 0.5;
}

.text-switch .btn-toggle.active:after {
    opacity: 1;
}

.text-switch .btn-toggle .handle {
    position: absolute;
    top: -9px;
    left: 0px;
    width: 32px;
    height: 32px;
    border-radius: 1.125rem;
    background: var(--theme-color);
    transition: left 0.5s;
}

.text-switch .btn-toggle.active .handle {
    left: 19px;
    transition: left 0.5s;
}

.offer-div {
    background-color: #00D13F;
    border-radius: 7px;
    padding: 4px 7px;
    color: var(--theme-white);
    font-family: var(--regular-font);
    font-size: 15px;
    line-height: 18px;
}

.package-price {
    font-size: 65px;
    line-height: 70px;
    font-family: var(--medium-font);
}

.package-input {
    width: 100%;
    max-width: 300px;
    margin: auto;
}

.counter-input {
    width: 100%;
    margin: auto;
    max-width: 210px;
    position: relative;
}

.counter-input .form-control {
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
}

.count {
    font-size: 20px;
    font-family: var(--regular-font);
    min-width: 44px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E6E6E6;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    cursor: pointer;
}

.count.c-plus {
    right: 0px;
}

.count.c-minus {
    left: 0px;
}

.feature-box {
    text-align: center;
}

.feature-box p {
    font-family: var(--regular-font);
    color: var(--theme-black);
    margin-bottom: 0px;
}

.feature-text-pad {
    padding: 8px 30px;
}

.feature-text-div {
    padding: 0px 10px;
    text-align: left;
}

.f-border {
    border-right: 1px solid #E0E8F1;
}

.f-tag {
    display: inline-block;
    background-color: var(--light-theme-bg1);
    color: var(--theme-color) !important;
    padding: 5px 20px;
    border-radius: 10px;
    min-width: 110px;
    text-align: center;
}

.light-red-bg {
    background-color: #FFEBEB;
    color: #FF3938 !important;
}

.moretext {
    display: none;
}

.choose-list {
    padding: 8px 15px;
    border-radius: 8px;
    margin-bottom: 5px;
    cursor: pointer;
}

.choose-list.active {
    background-color: var(--light-theme-bg1);
    color: var(--theme-color);
}

.choose-list span svg {
    margin-top: -3px;
}

.vertical-flex-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.successfully-shared {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    color: var(--theme-color);
    border-radius: 8px;
    background-color: var(--light-theme-bg1);
    padding: .75em 1em;
    width: 100%;
    max-width: 350px;
    font-family: var(--regular-font);
    font-size: 17px;
}

.successfully-shared.show {
    display: block;
}

.res-action-dropdown {
    display: none;
}

.res-action-dropdown.dropdown .dropdown-toggle {
    color: var(--theme-black);
}

.res-action-dropdown.dropdown .dropdown-toggle::before {
    display: none;
}

.res-action-dropdown.dropdown .dropdown-menu {
    box-shadow: 0px 0px 10px #00000029;
}

.action-drop-icon {
    min-width: 20px;
    width: 20px;
    height: auto;
    margin-right: 12px;
    text-align: center;
}

.res-action-dropdown .dropdown-item {
    font-family: var(--regular-font);
    padding: 0.2rem 1rem;
    font-size: 15px;
}

.input-group .btn {
    z-index: 0;
}

.selectBox {
    position: relative;
    cursor: pointer;
}

.selectBox__value {
    background-color: #fff !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    text-align: left;
}

.selectBox:after {
    position: absolute;
    right: 12px;
    bottom: 0px;
    color: var(--theme-black);
    transform: translateY(-50%) rotate(0deg);
    transition: all 0.2s ease-in-out;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.001' height='7.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E");
}

.selectBox .dropdown-menu {
    transition: all 0.5s ease-in-out;
    display: block;
    top: 100%;
    width: 100%;
    z-index: 10;
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 0px;
}

.selectBox.show:after {
    transform: translateY(-50%) rotate(180deg);
}

.selectBox.show .dropdown-menu {
    transition: all 0.3s ease-in-out;
    visibility: visible;
    opacity: 1;
    z-index: 10;
}

.min-100vh-relative-div {
    min-height: calc(100vh - 119px);
    position: relative;
    padding-bottom: 170px;
}

.audio-play-div {
    position: fixed;
    z-index: 99;
    bottom: 0px;
    left: 330px;
    right: 0px;
    width: calc(100% - 380px);
    background-color: var(--light-theme-bg);
    box-shadow: -20px -20px 20px #04274D0D;
    border-radius: 36px 36px 0px 0px;
    padding: 35px 140px 12px;
}

.audio-details-div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-play-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 25px;
}

.audio-play-icons img,
.audio-play-icons svg {
    max-height: 23px;
    width: auto;
}

.play-theme-round {
    height: 60px;
    width: 60px;
    min-width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    margin: 0px 25px;
}

.play-speed {
    color: var(--theme-color);
    height: 51px;
    width: 51px;
    border-radius: 50%;
    border: 2px solid var(--theme-color);
    font-family: var(--medium-font);
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.play-time {
    color: var(--theme-color);
    font-size: 15px;
    border-radius: 50px;
    border: 2px solid var(--theme-color);
    font-family: var(--medium-font);
    border-radius: 27px;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.slider-theme-icon {
    min-width: 70px;
    width: 70px;
    height: 70px;
    padding: 10px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: #D4E8FF;
    color: #088AFF;
}

.slider-theme-icon-small {
    min-width: 50px;
    width: 50px;
    height: 50px;
}

.slider-p {
    font-family: var(--regular-font);
    color: var(--theme-black);
    margin-bottom: 0px;
}

.slider-hover-card {
    position: relative;
    cursor: pointer;
    margin: 0px 12px;
    box-shadow: 8px 8px 8px #1A242E08;
}

.slider-hover-box {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #088AFF;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all ease .5s;
    padding: 20px;
}

.slider-hover-box p {
    color: #fff;
    margin-bottom: 0px;
}

.slider-hover-card:hover .slider-hover-box {
    opacity: 1;
}

.yellow-theme-slider .slider-theme-icon {
    background-color: #FFECD4;
    color: #FF9819;
}

.yellow-theme-slider .slider-hover-box {
    background-color: #FF9819;
}

.pink-theme-slider .slider-theme-icon {
    background-color: #FFDFFC;
    color: #A60298;
}

.pink-theme-slider .slider-hover-box {
    background-color: #A60298;
}

.file-slider .carousel-indicators {
    position: relative;
    margin-top: 10px;
}

.file-slider .carousel-indicators [data-bs-target] {
    background-color: var(--theme-color);
    opacity: .2;
}

.file-slider .carousel-indicators .active {
    opacity: 1;
}

.water-slider .range-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.water-slider .range-slider input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
}

.water-slider .range-slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.water-slider .range-slider input[type=range]:focus {
    outline: none;
}

.water-slider .range-slider input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.water-slider .range-slider__range {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #d7dcdf;
    outline: none;
    padding: 0;
    margin: 5px 0px;
    z-index: 9;
    cursor: pointer;
}

.water-slider .range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--theme-color);
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.water-slider .range-slider__range::-webkit-slider-thumb:hover {
    background: var(--theme-color);
}

.water-slider .range-slider__range:active::-webkit-slider-thumb {
    background: var(--theme-color);
}

.water-slider .range-slider__range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: var(--theme-color);
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.water-slider .range-slider__range::-moz-range-thumb:hover {
    background: var(--theme-color);
}

.water-slider .range-slider__range:active::-moz-range-thumb {
    background: var(--theme-color);
}

.range-slider__value {
    display: none;
}

.nav-pills.btn-pills {
    border-bottom: none;
}

.nav-pills.btn-pills .nav-link {
    background: var(--light-theme-bg);
    border: 1px solid var(--theme-color);
    border-radius: 50px;
    border-bottom: 1px solid;
    padding: 6px 25px;
    color: var(--theme-color);
    opacity: 1;
    margin-right: 10px;
    font-family: var(--regular-font);
}

.nav-pills.btn-pills .nav-link.active,
.nav-pills.btn-pills .show>.nav-link {
    color: var(--theme-white);
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    opacity: 1;
}

.btn-tab-icon img {
    height: 24px;
    width: auto;
}

.btn-active-icon {
    display: none;
}

.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-reg-icon {
    display: none;
}

.nav-pills.btn-pills .nav-link.active .btn-tab-icon img.btn-active-icon {
    display: inline-block;
}

.file-action-icon-flex {
    display: inline-flex;
    flex-wrap: wrap;
}

.file-action-list {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #E5E5E5;
    cursor: pointer;
}

.file-action-list img,
.file-action-list svg {
    width: 100%;
    max-width: 20px;
    height: auto;
}

.water-slider.text-slider .range-slider__range {
    height: 12px;
}

.water-slider.text-slider .range-slider__range::-webkit-slider-thumb {
    width: 30px;
    height: 30px;
}

.water-slider.text-slider .range-slider__range::-moz-range-thumb {
    width: 30px;
    height: 30px;
}

.selectBox.disable .selectBox__value {
    background-color: var(--light-theme-bg1) !important;
}

.selectBox.disable.show .dropdown-menu {
    visibility: hidden !important;
    opacity: 0 !important;
}

.selectBox.disable.show:after {
    transform: translateY(-50%) rotate(0deg) !important;
}

.info-icon {
    min-width: 25px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-theme-bg1);
    color: var(--theme-color);
    margin-right: 8px;
    margin-top: 2px;
}

.credit-round-btn {
    background-color: var(--light-theme-bg);
    border: 1px solid var(--theme-color);
    line-height: 20px !important;
}

.credit-round-btn .ft-18 {
    line-height: 20px !important;
}

.credit-round-btn .round-color-icon {
    height: 40px;
    width: 40px;
    min-width: 40px;
}

.files-tab-div-height {
    width: 100%;
    /*height: calc(100vh - 380px);*/
    height: calc(100vh);
    overflow-y: auto;
}

.files-tab-div-height::-webkit-scrollbar,
.ai-chat-div-height::-webkit-scrollbar,
.transkript-list-res-height::-webkit-scrollbar {
    width: 10px;
}

.files-tab-div-height::-webkit-scrollbar-track,
.ai-chat-div-height::-webkit-scrollbar-track,
.transkript-list-res-height::-webkit-scrollbar-track {
    background: #F2F2F2;
    border-radius: 50px;
}

.files-tab-div-height::-webkit-scrollbar-thumb,
.ai-chat-div-height::-webkit-scrollbar-thumb,
.transkript-list-res-height::-webkit-scrollbar-thumb {
    background: #C8C8C8;
    border-radius: 50px;
}

.files-tab-div-height .nav-pills.btn-pills {
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 9;
}

.ai-chat-div-height {
    overflow-y: auto;
}

.ai-chat-div {
    border-right: 1px solid #E3E3E3;
}

.ai-chat-msg-box {
    display: flex;
}

.ai-chat-msg-content {
    height: 100%;
    overflow-y: auto;
    padding-right: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.ai-chat-msg-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
}

.ai-chat-msg-content::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
}

.ai-chat-msg-content::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
}

.ai-chat-msg-content::-webkit-scrollbar-thumb:hover {
    background-color: #009688;
}

.ai-chat-msg-box .theme-btn img {
    min-width: 20px;
    width: 20px;
}

.ai-chat-msg-list {
    display: flex;
    margin-top: 10px;
}

.ai-chat-msg-list p {
    margin-bottom: 10px;
}

.transkript-list {
    background-color: #fff;
    border-radius: 7px;
    display: flex;
    align-items: center;
    padding: 2px 15px;
    transition: all ease.5s;
}

.transkript-list:hover,
.transkript-list.active {
    background-color: #E4E4E4;
}

.transcript-dropdown {
    min-width: 130px;
    width: 130px;
    margin-right: 10px;
}

.transcript-dropdown .dropdown-toggle {
    min-width: 130px;
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    border-radius: 11px;
    color: var(--theme-color);
    padding: 6px 15px;
}

.transcript-dropdown .dropdown-menu {
    min-width: 250px;
}

.transcript-time {
    min-width: 110px;
    padding: 0px 15px;
    color: var(--light-color);
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
}

.transcript-time-icon svg {
    margin-top: -2px;
    margin-right: 6px;
}

.transcript-content {
    color: var(--theme-black);
    font-family: var(--regular-font);
    width: 80%;

}

.transcript-content:focus {
    outline: none;
}

.shortcodes-p p {
    margin-bottom: 8px;
}


.filename::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Webkit */
}

.status {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.spinner {
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: currentColor;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.processing {
    color: #999;
    /* Light color for processing */
}

.failed {
    color: #ff4d4d;
    /* Red color for failed */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.chat-list-box {
    font-size: 15px;
    line-height: 20px;
    background-color: #f4f4f5;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
}

.chat-list-box p {
    color: #000;
}

.semi-bold {
    font-weight: 600
}

.loading-threedots {
    font-size: 30px;
}

.loading-threedots span {
    opacity: 0;
    animation: blink 1.4s infinite;
}

.loading-threedots span:nth-child(2) {
    animation-delay: .2s;
}

.loading-threedots span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

::-webkit-scrollbar {
    display: none;
    overflow: hidden;
}

.files-tab-div-height::-webkit-scrollbar {
    display: block;
    overflow: auto;
}

.dropdown-menu::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 10px;
    background-color: #FFFF;

}

.dropdown-menu::-webkit-scrollbar-track {
    background: #F2F2F2;
    border-radius: 50px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background: #C8C8C8;
    border-radius: 50px;
}



.disabled-btn {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.highlighted {
    background-color: var(--light-theme-bg1);
}

.payment-bold {
    font-weight: 600;
    color: var(--base-color);
}

.time-speaker-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mobile-view {
    display: none;
}

.burger-mobile-view {
    display: none;
}

.burger-web-view {
    display: block;
}

.aichat-enablecard {
    width: 50%;
}

.recorder-span {
    width: 52px;
    height: 48px;
}

.recorder-span2 {
    width: 46px;
    height: 38px;
}

.recorder-img {
    width: 26px;
    height: 24px;
}

.subtype-span {
    font-size: small;
}

.payment-row {
    cursor: pointer;
}

.payment-card {
    cursor: pointer;
    transition: all 0.3s;
    /* Smooth transition for the effects */
}

.payment-card:hover {
    /* Example: Slight scale for a pop effect */
    transform: scale(1.03);

    /* Example: Add a shadow for depth */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*.scale75 {
    transform: scale(0.75);
    transform-origin: top left;
  }
  */
.hover-effect {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.hover-effect:hover {
    transform: translateY(-5px);
}

.hover-span {
    visibility: hidden;
    background-color: #FFFFFF;
    color: var(--light-color);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 20;
    bottom: 105%;
    /* Position the tooltip above the icon */
    left: 50%;
    margin-left: -30px;
    /* Use half of the width value to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 20px;
    white-space: nowrap;
    /* Prevent content from wrapping to the next line */
}

.hover-effect:hover .hover-span {
    visibility: visible;
    opacity: 1;
}

.google-div {
    max-width: 200px;
    overflow: hidden;
}


.info-terms {
    font-size: 12px;
    color: #aaaaaa;
}

.info-terms a {
    color: #0d6efd;
}

.payment-btn {
    transition: all 0.2s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1) !important;
}

.top-right-modal .modal-dialog {
    position: fixed;
    top: 2rem;
    right: 2rem;
    margin: 0;
    min-width: 400px;
    /* Match the width of the modal to your screenshot */
}

.webcam-circle {
    display: none;
    /* default state, will be overridden by JavaScript when recording starts */
    position: fixed;
    bottom: 90px;
    left: 100px;
    width: 240px;
    height: 240px;
    border: 2px solid #FF623E;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1001;
}

.webcam-video {
    height: 240px;
    width: 240px;
    object-fit: cover;
}

.video-controller {
    display: none;
    /* default state, will be overridden by JavaScript when recording starts */
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 50px;
    width: 300px;
    background-color: #333;
    border-radius: 30px;
    padding: 10px;
    margin: 20px;
    border: 2px solid #fff;
    z-index: 1001;
}



.loading-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-modal-content {
    border-radius: 4px;
    padding: 10px;
    text-align: center;
}

.loading-loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--theme-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: 0 auto;
    margin-top: 10px;
}

.loading-text {
    color: var(--theme-color);
    font-size: 24px;
    /* Remove the quotes */
    font-weight: bold;
    /* Remove the quotes */
}

.loading-modal2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
}

.loading-modal-content2 {
    /* background-color: white; */
    border-radius: 4px;
    text-align: center;
}

.loading-loader2 {
    border: 4px solid #f3f3f3;
    border-top: 4px solid var(--theme-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.0s linear infinite;
    margin: 0 auto;
}

.loading-text2 {
    color: var(--theme-color);
    font-size: 28px;
    font-weight: bold
}

.modal-overlay2 {
    display: flex;
    /* Allows centering using justify-content and align-items */
    justify-content: center;
    /* Horizontally centers the modal content */
    align-items: center;
    /* Vertically centers the modal content */
    position: fixed;
    /* Fixed position to cover entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    z-index: 1000;
    /* Make sure it's on top of other elements */
}

.play-speed {
    color: var(--theme-color);
    height: 51px;
    width: 51px;
    border-radius: 50%;
    border: 2px solid var(--theme-color);
    font-family: var(--medium-font);
    border-radius: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.chat-message {
    padding: 5px 10px;
    word-wrap: break-word;
    font-size: 0.9rem;
    display: inline-flex; /* Or use inline-block, depending on your layout needs */
    max-width: 80%;
}




.user-message {
    background-color: #e7f3fe;
    color: var(--theme-black);
    align-self: flex-end;
    margin-left: 15px;
    padding: 10px;
    border-radius: 10px 10px 0 10px;
    word-wrap: break-word;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: var(--regular-font)
}




.bot-response {
    background-color: #f1f0f0;
    /* Light grey background */
    color: var(--theme-black);
    align-self: flex-start;
    margin-right: 15px;
    padding: 10px;
    border-radius: 10px 10px 10px 0;
    word-wrap: break-word;
}



.bot-response p {
    color: var(--theme-black);
    font-family: var(--regular-font)
}




.green-outline-btn {
    background-color: white;
    /* White background */
    color: #009688;
    /* Green text color */
    border: 1px solid #009688;
    /* Green border */
    margin-top: 5px;
    border-radius: 10px;
}


.type-file::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
}

.type-file::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
    
}

.type-file::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
}

.type-file::-webkit-scrollbar-thumb:hover {
    background-color: #009688;
}


bodY {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, cyan, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  .file-view {
    width: 70%;
    margin: 0 auto;
    font-size: 16px;
    height: 600px;
    overflow-y: auto;
    padding-right: 10px;
    text-align:justify
  }

  .file-view h1 {
    font-size: 22px; 
    font-weight: bold;
  }
  
  .file-view h2 {
    font-size: 20px; 
    font-weight: bold;
  }
  
  .file-view h3 {
    font-size: 18px; 
    font-weight: bold;
  }
  
  .file-view ol {
    list-style-type: decimal; 
  }
  
  .file-view ul {
    list-style-type: disc;
  }
  
  .file-view ol, .file-view ul {
    padding-left: 20px; 
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* This will create a responsive grid layout */
    align-items: start; /* Align items to the start of the grid area */
  }
  
  .feature-item {
    display: flex;
    flex-direction: column; /* Stack the image and paragraph vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center the text */
    margin-bottom: 10px; /* Add some space below each item */
  }
  
  .feature-item img {
    margin-bottom: 5px; /* Space between the image and the paragraph */
  }

  .file-session-container {
    font-family: 'Arial', sans-serif;
}

.file-session-tab-bar,
.file-session-tab-bar-workspace {
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    max-width: 300px; /* Adjust the max width as needed */
    overflow: hidden;
    padding: 6px;
    margin-bottom: 20px;
}


.file-session-tab {
    flex: 1;
    padding: 6px 16px;
    text-align: center;
    background: var(--light-theme-bg1);
    color: var(--theme-color);
    border: none;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 30px;
    font-size: large;
    margin: 0 5px;
}



.file-session-tab.active {
    background-color: var(--theme-color);
    color: #fff;
}

.file-view::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
}

.file-view::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 4px;
}

.file-view::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
}

.file-view::-webkit-scrollbar-thumb:hover {
    background-color: #009688;
}

.toggle-container {
    display: flex;
    align-items: center;
    /* Vertically centers the flex items */
    justify-content: center;
    /* Horizontally centers the flex items */
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
input:checked+.slider {
    background-color: #009688;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009688;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.chat-view {
    position: fixed;
}